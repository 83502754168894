<template>
  <div class="w-full h-full pr-20">
    <div class="text-left text-c18 font-c4">
      Identifiant
    </div>

    <div class="text-left mt-4">
      <div class="inputo h-60 flex pl-4 items-center rounded-10">
        <icon
            :data="icons.ic_user"
            height="30"
            width="30"
            class="mr-2"
            original
        />
        <input
            v-model="identifiant"
            type="text"
            placeholder=""
            class="h-full w-full text-c18"
        >
      </div>
    </div>

    <div class="flex mt-6">
      <div class="text-left text-c18 font-c4 w-2/3">
        Mot de passe
      </div>
      <div class="text-left text-c18 font-c4 w-1/3 text-right">
        <span
            v-if="typePassword === 'password'"
            class="cursor-pointer"
            @click="afficher"
        >
          Afficher
        </span>
        <span
            v-if="typePassword === 'text'"
            class="cursor-pointer"
            @click="cacher"
        >
          Cacher
        </span>
      </div>
    </div>


    <div class="text-left mt-4">
      <div class="inputo h-60 flex pl-4 items-center rounded-10">
        <icon
            :data="icons.ic_lock"
            height="25"
            width="25"
            class="mr-3"
            original
        />
        <input
            v-model="password"
            :type="typePassword"
            placeholder=". . . ."
            class="h-full w-full text-c18"
        >
      </div>
    </div>

    <div
        v-if="error !== null"
        class="mt-4 text-red"
    >
      {{ error }}
    </div>

    <div class="mt-4">
      <div class="text-oho-blue-clear text-c18 font-c4 underline cursor-pointer">
        Mot de passe oublié ?
      </div>
    </div>

    <div
        class="mt-6 h-60 bg-oho-blue flex justify-center items-center text-c18 text-white rounded-10 cursor-pointer"
        @click="goNext"
    >
      <easy-spinner
          v-if="charge"
          type="dots"
          size="30"
      />
      <div v-if="!charge">
        Connexion
      </div>
    </div>
  </div>
</template>

<script>
import ic_user from '../../../assets/icons/user.svg'
import ic_lock from '../../../assets/icons/lock.svg'
import apiroutes from '@/router/api-routes'
import http from '@/plugins/https'

export default {
  name: "Index",
  components: {
  },
  data () {
    return {
      charge: false,
      identifiant: '',
      password: '',
      error: null,
      typePassword: 'password',
      icons: {
        ic_user,
        ic_lock
      }
    }
  },
  methods: {
    afficher(){
      this.typePassword = 'text'
    },

    cacher(){
      this.typePassword = 'password'
    },

    goNext(){
      this.error = null
      if (this.identifiant.length > 0 && this.password.length > 0){
        this.charge = true
        this.login()
      } else {
        this.error = 'Vérifiez vos identifiants et ressayez'
      }
    },

    login(){
      http.post(apiroutes.baseURL + apiroutes.login, {
        email: this.identifiant,
        password: this.password
      })
          .then(response => {
            this.charge = false
            console.log('yes')
            this.$emit('cTrain', response.token)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.data.statusCode === 401){
              this.error = 'Identifiants incorrectes'
            }
          })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

.inputo{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
}
.inputo:focus-within {
  border-color: $focus-color;
}
input{
  background-color: transparent;
}
.button{

}
</style>
